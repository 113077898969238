import {BUILD_VERSION as _buildVersion} from './server-variables';

export const API_VERSION = 'v1.0';
export const BUILD_VERSION = _buildVersion;
export const CLIENT_VERSION = '3.0.0 b:#' + BUILD_VERSION;

export function setApiRelease(headers) {
    API_BUILD = headers["x-api-build"];
    API_BRANCH = headers["x-api-branch"];
    API_COMMIT = headers["x-api-commit"];
    API_RELEASE_STRING = "#" + API_BUILD +
        " Branch: " + API_BRANCH +
        " Commit:" + API_COMMIT;
}

export let API_RELEASE_STRING = '';
export let API_BUILD = '';
export let API_BRANCH = '';
export let API_COMMIT = '';

export const getBaseUrl = (url) => {
    if (url == null) {
        if (typeof window !== "undefined" && window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '10.10.110.51') {
            return 'https://sig-integration.godoo.ch';
        }
    } else {
        return `https://${url}`;
    }
};

const isOnCloud = (baseURL) => {
    if (!baseURL || baseURL === "localhost") {
        return false;
    }
    const hostnameParts = baseURL.split(".");
    if (hostnameParts.length < 2) {
        return false;
    }
    return hostnameParts[hostnameParts.length - 2] === "godoo" && hostnameParts[hostnameParts.length - 1] === "app"
}

export const prefix = (baseURL) => {
    if (isOnCloud(baseURL))
        return { TRANSLATION: '', CALENDAR: '' }
    
    return { TRANSLATION: '/translation', CALENDAR: '/calendar' }
};

export const getApiUrl = (url) => {
    const baseUrl = getBaseUrl(url);
    return `${baseUrl}/${API_VERSION}`;
};
export const HOME_URL = '/';

export const entityTypes = {
    "organisation": {
        name: "Organisation",
        alias: "organisation",
    },
    "user_group": {
        name: "User Group",
        alias: "userGroup",
    },
};

export const DEVICE_TYPES = {
    MOBILE: 'mobile',
    WEB: 'webui',
    ADAPTER: 'adapter',
};

export const CUSTOM_LOGO = 'customLogo';
export const CUSTOM_LOGO_NAME = 'logo-full.png';
