import * as config from '../constants/globalConfiguration';
import toastr from 'toastr';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';

import i18next from 'i18next';

const loadingBar = require('nprogress');

toastr.options = {
  "closeButton": true,
  "debug": false,
  "newestOnTop": false,
  "progressBar": true,
  "positionClass": "toast-top-right",
  "preventDuplicates": false,
  "onclick": null,
  "showDuration": "200",
  "hideDuration": "3000",
  "timeOut": "9000",
  "extendedTimeOut": "2500",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "tapToDismiss": false,
  "hideMethod": "fadeOut",
  "showAnimation": "animated bounceIn", // or other animations from animate.css
  "hideAnimation": "animated bounceOut"
};

export function showMessage(type, message) {
  toastr[type](message);
}


export const cases = {
  CAPITALIZE: "capitalise",                   //Aaa Bbb. Ccc
  LOWERCASE: "lowercase",                     //aaa bbb. ccc
  UPPERCASE: "uppercase",                     //AAA BBB. CCC
  CAPITALIZE_SENTENCE: "capitalize_sentence", //Aaa bbb. Ccc
  NONE: "none",
}

export function __(translationKey, sentenceCase) {

  if (translationKey === undefined || translationKey === null) {
    return '.'
  }

  let translationValue = i18next.store && _.isString(translationKey) ? i18next.t(translationKey.trim().toLowerCase(), translationKey, { keySeparator: false, nsSeparator: '' }) : translationKey

  switch (sentenceCase) {
    case cases.CAPITALIZE:
      return translationValue.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    case cases.LOWERCASE:
      return translationValue.toLowerCase();
    case cases.UPPERCASE:
      return translationValue.toUpperCase();
    case cases.NONE:
      return translationValue
    default:
    case cases.CAPITALIZE_SENTENCE:
      let sentenceArray = translationValue.split('.')
      let capsArray = sentenceArray.map(word => {
        return word.replace(word[0], word[0]?.toUpperCase() || "");
      });
      return capsArray.join('.');
  }

}

export function getAuthData() {
  let authObj = localStorage.getItem('auth');
  if (authObj) {
    return JSON.parse(authObj);
  }
  return null;
}

export function renameProp(obj, oldKey, newKey) {
  obj[newKey] = obj[oldKey];
  delete obj[oldKey];
}


export function getApiHandler(url) {
  const api = axios.create({
    baseURL: config.getApiUrl(url)
  });

  const tokenFromLocalStorage = getAuthData()?.token

  if (tokenFromLocalStorage) {
    api.defaults.headers.common['x-auth-token'] = tokenFromLocalStorage
  }

  // Set other needed Headers
  api.defaults.headers.common['Client-Type'] = config.DEVICE_TYPES.WEB;
  api.defaults.headers.common['Content-Type'] = 'application/json';
  api.defaults.headers.common['Client-Version'] = config.CLIENT_VERSION;
  api.defaults.headers.common['TimeZoneOffset'] = moment().utcOffset();
  api.defaults.headers.common['Language'] = window.localStorage.getItem('lang')

  api.interceptors.response.use(function (response) {
    config.setApiRelease(response.headers);
    return response;
  }, function (error) {
    console.log({ error })
    if (error.message === "Network Error") {
      return Promise.reject("logout");
    }

    loadingBar.done();
    if (error.message === 'canceled') {
      return;
    }

    // failed get api response from calendar, right now doesnt displays error messages
    const hideErrorMessages = (error?.config?.url?.indexOf("calendar") > -1) && (error?.config?.method === "get")
    // Do something with response error
    if (typeof error.response !== 'undefined' && !hideErrorMessages) {
      if (typeof error.response.data === 'undefined') {
        showMessage('error', '<strong>' + 'Error:' + '</strong><br/>' + error);
      } else {
        let err = error.response.data;
        let link = '';
        if (err.logId != '') {
          link = 'LogId: <a target="_blank" href="' + window.location.origin + '#logs/' + err.logId + '">' + err.logId + '</a>';
        }
        let message = err.message ? '<br/>' + err.message : '';

        // Validation error
        if (error.response.status == 400) {
          message = err.details;
        }

        showMessage('error', '<strong>' + 'Error ' + err.code + '.' + '</strong>' + message + '<br/>' + link + '<br/>' + '<a href="javascript:void(0)" class="jira_task_modal"><strong>' + 'Give us a FeedBack' + '</strong></a>');
        window.JIRA_TASKS && window.JIRA_TASKS.addEventOpenModal(err.logId);
      }
    }


    return Promise.reject(error);
  });

  return api;
}


