import React from 'react';
import PropTypes from 'prop-types';
import { __ } from '../utils/appHelper';
import Loader from 'react-loader';

class Loading extends React.Component {

  static propTypes = {
    process: PropTypes.object,
    order: PropTypes.object,
    modelValues: PropTypes.object,
    isModal: PropTypes.bool,
    match: PropTypes.object
  };

  render() {
    return (
      <div className="loading-curtain">
        <Loader loaded={false}>
          {__('loading')}
        </Loader>
      </div>

    );
  }
}
export default Loading;
