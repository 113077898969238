import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { CUSTOM_LOGO, getApiUrl, HOME_URL } from '../../constants/globalConfiguration'
import { connect } from 'react-redux';
import { ReactComponent as DefaultLogo } from "../../images/logo-full.svg";
import { ReactComponent as DefaultLogoLight } from "../../images/logo-light-full.svg";
import axios from "axios";

export const DEFAULT_LOGO_URL = "@@default@@";
const Logo = (props) => {
  const { className, light, homeURL, baseURI } = props
  const [logoUrl, setLogoUrl] = useState(null);

  let logoImage = null;

  useEffect(() => {
    const url = getApiUrl(baseURI);
    return axios.get(`${url}/systemConfigurations`)
      .then(response => {
        let systemConfiguration = {};
        response.data.forEach(
          (c) => {
            if (c.key === "instanceOwner") {
              systemConfiguration['instanceOwner'] = !!parseInt(c.value);
              return;
            }
            systemConfiguration[c.key] = c.value;
          });

        //TODO
        axios.head(`${url}/staticFiles/logo/${systemConfiguration?.[CUSTOM_LOGO]}`)
          .then(() => setLogoUrl(`${url}/staticFiles/logo/${systemConfiguration?.[CUSTOM_LOGO]}`))
          .catch(() => setLogoUrl(DEFAULT_LOGO_URL))
      })
      .catch((err) => {
        console.error('Cannot fetch system settings. Got:', err);
        setLogoUrl(DEFAULT_LOGO_URL)
      });

  }, [baseURI]);


  if (DEFAULT_LOGO_URL === logoUrl) {
    if (light) {
      logoImage = (<DefaultLogoLight className="logo" alt="logo" />);
    } else {
      logoImage = (<DefaultLogo className="logo" alt="logo" />);
    }
  } else if (logoUrl !== null) {
    logoImage = (<img src={logoUrl} className="logo" alt="logo" />);
  }

  return (
    <Link className={`logoContainer ${className}`} title="godoo" to={homeURL || HOME_URL}>
      {logoImage}
    </Link>
  )
};

Logo.propTypes = { className: PropTypes.string, forceGodoo: PropTypes.bool, light: PropTypes.bool };
Logo.defaultProps = {
  className: '',
  light: false
};


const mapStateToProps = (state) => {
  return {
    homeURL: state.ui.homeURL,
    baseURI: state.ui.baseURI,
  };
};


export default connect(mapStateToProps)(Logo);