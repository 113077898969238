import {__} from "../../utils/appHelper";
import {Switch} from "antd";
import React from "react";

const HorizontalSwitch = (props) => {
	return (
		<div style={{ width: "100%" }}>
			<label
				htmlFor="automaticLanguageDetectionSwitch"
				style={{ float: "left" }}
			>{__("automatic language detection")}</label>
			<Switch
				{...props}
				checked={props.value}
				id={"automaticLanguageDetectionSwitch"}
				style={{ float: "right", marginTop: "3px" }}
			/>
		</div>
	)
}

export default HorizontalSwitch
