import React from 'react';
import './App.css';
import moment from 'moment'
import {detect} from 'detect-browser'
import {ApiProvider, ReduxProvider, StyleProvider} from './providers'
import NotSupportedBroswer from './containers/NotSupportedBroswer'
import Routes from './Routes.js';
import {HashRouter as Router} from 'react-router-dom';
import HistoryProvider from "./providers/HistoryProvider";

moment.locale(window.navigator.userLanguage || window.navigator.language)

function App() {

    const browser = detect();

    if (browser && browser.name === "ie") return <NotSupportedBroswer browser={browser}/>;

    return (
        <div className="App">
            <Router>
            <ReduxProvider>
                <ApiProvider>
                    <StyleProvider>
                            <HistoryProvider>
                                <Routes/>
                            </HistoryProvider>
                    </StyleProvider>
                </ApiProvider>
            </ReduxProvider>
            </Router>
        </div>
    );
}

export default App;
