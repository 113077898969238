import { combineReducers } from 'redux';
import user from './userReducer';
import errors from './errorReducer';
import uiReducer from './uiReducer';
import { reducer as reduxFormReducer } from 'redux-form';
const rootReducer = combineReducers({
  user,
  errors,
  ui: uiReducer,
  form: reduxFormReducer,
});

export default rootReducer;
