import {THEME_CLASSIC} from "../../../utils/constants";

export default {
    user: {
        auth: JSON.parse(localStorage.getItem('auth')) || null,
        data: null,
        isLoading: false,
        reports: [],
        views: [],
        orderGroups: [],
    },
    enumValues: {},
    errors: {
        loginError: null
    },
    menu: {
        "items": []
    },
    ui: {
        theme: THEME_CLASSIC,
        language: null,
        customColors: {
            primary: null,
            secondary: null,
            tertiary: null,
        },
        remoteSystems: null,
        baseURI: null,
        entityNameForSignUp: null,
    },
    entities: {
        searchOrganisationsForm: null,
        searchOrganisationsResults: []
    },
    import: {
        reportData: null,
    },
    selectedTemplateRights: null,
};
