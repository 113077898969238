import React from 'react';
import moment from 'moment';
import { Typography, Image } from 'antd';

const { Title, Text } = Typography;

const LogoSrc = require('./../images/logo-light-full.svg')

const supportedBrowsers = [
  {
    src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Firefox_logo%2C_2019.svg/1920px-Firefox_logo%2C_2019.svg.png',
    url: 'https://www.firefox.com',
    name: 'Firefox'
  }, {
    src: 'https://upload.wikimedia.org/wikipedia/it/9/9e/Google_Chrome_logo.png',
    url: 'https://www.google.com/chrome/',
    name: 'Chrome'
  }, {
    src: 'https://upload.wikimedia.org/wikipedia/commons/5/52/Safari_browser_logo.svg',
    url: 'https://www.apple.com/safari',
    name: 'Safari'
  }, {
    src: 'https://upload.wikimedia.org/wikipedia/it/9/98/Microsoft_Edge_logo_%282019%29.svg',
    url: 'https://www.microsoft.com/edge',
    name: 'Edge'
  }

]

export default ({ browser }) => {

  return <div style={{
    background: '#0d4d76',
    color: '#fff'
  }}>
    <div className="container">

      <Image width={200} src={LogoSrc} />
      <div id="main-content">

        <Title>Oh snap!<br />We don't support this version of your browser, and neither should you!</Title>
        <Text>You are visiting this page because we detected an unsupported browser. Your browser does not support
          security
          features that we require. We highly recommend that you update your browser. If you believe you have arrived
          here
          in error, please <a style={{ color: '#fff', textDecoration: 'underline' }} href={'mailto:info@e-globe-solutions.com'}>contact us</a>. Be
          sure to include your browser version.</Text>
      </div>
      <div style={{ display: 'flex', textAlign: 'center', alignItems: 'center', margin: 'auto' }}>
        {supportedBrowsers.map(({ src, name, url }) =>
          <a style={{ marginRight: '5rem', marginLeft: '5rem', color: '#fff' }} target='_blank' href={url}>
            <Image width={100} style={{ margin: '40px auto 15px', display: 'block' }} src={src} />
            {name}
          </a>)}
      </div>
      <footer className="dont-print" style={{
        color: '#fff'
      }}>
        <ul>
          <li>
            <a target="_blank" style={{
              color: '#fff'
            }} href="http://www.e-globe-solutions.com">e-globe solutions
              AG</a> ©{moment().format('YYYY')}
          </li>
        </ul>
      </footer>
    </div>
  </div>

}


