import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { forgotPasswordRequest } from '../providers/ReduxProvider/actions/userActions';
import { Authentication as Auth } from "components"

import { THEME_CUSTOM, THEME_LIGHT } from "../utils/constants";
import { isLight } from "../utils/styleUtils";
import Logo from "../components/common/Logo";
import { __ } from '../utils/appHelper'
import { T } from "../utils/translations"
import getErrorText from "../utils/getErrorText";

const textCommons = { T, __, getErrorText }

const ForgotPasswordPage = (props) => {
  const theme = useSelector((state) => state?.ui?.theme);
  const customColors = useSelector((state) => state?.ui?.customColors);

  const handleSubmit = values => {
    let payload = { values };
    props.forgotPasswordRequest(payload);
  };

  return (
    <div>
      <div className='row'>
        <div
          className="col-xs-12 col-sm-6 col-sm-offset-3 col-md-6 col-md-offset-3 col-lg-6 col-lg-offset-3 col-xl-2 col-xl-offset-5">
          <Logo
            light={!(theme === THEME_LIGHT || (theme === THEME_CUSTOM && isLight(customColors.primary)))}
          />
        </div>
      </div>
      <div className='row'>
        <div
          className="col-xs-12 col-sm-6 col-sm-offset-3 col-md-6 col-md-offset-3 col-lg-6 col-lg-offset-3 col-xl-2 col-xl-offset-5">
          <Auth.ForgotPasswordForm handleSubmit={handleSubmit} textCommons={textCommons} />
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => {
    return {
      isLoading: state.user.isLoading
    };
  },
  { forgotPasswordRequest }
)(ForgotPasswordPage);

ForgotPasswordPage.propTypes = {
  forgotPasswordRequest: PropTypes.func,
  isLoading: PropTypes.bool,
};