import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { getEntitySettings } from '../providers/ReduxProvider/actions/userActions'

import { Authentication as Auth } from 'components'
import { Gutter } from 'components/dist/styles'
import { Text } from 'components/dist/styles'
import { api } from '../providers/ApiProvider'
import { __ } from '../utils/appHelper'
import { T } from "../utils/translations"
import getErrorText from "../utils/getErrorText";

const textCommons = { T, __, getErrorText }

const SuccessMessagePage = (props) => {
  const { homeURL } = props

  const { userRegistration } = useSelector((state) => state?.ui?.entitySettings) || {}
  const { successfulSignUpPageConfiguration } = userRegistration || {}

  const entityName = props.match.params.entityName;

  useEffect(() => {
    api.get(`register/availableEntities/${entityName}`).then((response) => {
      props.getEntitySettings(response.data.id);
    })
  }, [])

  return (
    <>
      <Text center>
        <Gutter bottom>
          <h3>
            {entityName || ''}
          </h3>
        </Gutter>
      </Text>
      <Auth.SuccessMessage
        message={successfulSignUpPageConfiguration?.message}
        backUrl={homeURL}
        backText={T?.back}
        textCommons={textCommons}
      />
    </>
  )
}

export default connect(
  (state) => {
    return {
      homeURL: state.ui.homeURL,
    }
  },
  { getEntitySettings }
)(SuccessMessagePage)
