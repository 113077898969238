// User
export const SET_HOME_URL = 'SET_HOME_URL';
export const USER_LOGGING_IN = 'USER_LOGGING_IN';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const USER_GET_DATA_ERROR = 'USER_GET_DATA_ERROR';
export const USER_GOT_DATA = 'USER_GOT_DATA';
export const USER_GOT_REPORTS = 'USER_GOT_REPORTS';
export const USER_GOT_ORDERTYPES = 'USER_GOT_ORDERTYPES';
export const USER_GOT_VIEWS = 'USER_GOT_VIEWS';
export const USER_GOT_ORDERGROUPS = 'USER_GOT_ORDERGROUPS';
export const USER_DELETED = 'USER_DELETED';
export const USER_GOT_RIGHTS = 'USER_GOT_RIGHTS';
export const USER_SIGN_UP_START = 'USER_SIGN_UP_START';
export const USER_SIGN_UP_FINISHED = 'USER_SIGN_UP_FINISHED';
export const USER_SIGN_UP_ERROR = 'USER_SIGN_UP_ERROR';
export const USER_FORGOT_PASSWORD_REQUEST_START = 'USER_FORGOT_PASSWORD_REQUEST_START';
export const USER_FORGOT_PASSWORD_REQUEST_FINISHED = 'USER_FORGOT_PASSWORD_REQUEST_FINISHED';
export const USER_FORGOT_PASSWORD_REQUEST_ERROR = 'USER_FORGOT_PASSWORD_REQUEST_ERROR';

// api
export const SET_BASE_URI = 'SET_BASE_URI';
export const SET_ENTITY_NAME_FOR_SIGN_UP = 'SET_ENTITY_NAME_FOR_SIGN_UP';


// ui
export const THEME_SELECTED = 'THEME_SELECTED';
export const CUSTOM_COLORS_SELECTED = 'CUSTOM_COLORS_SELECTED';
export const LANGUAGE_SELECTED = 'LANGUAGE_SELECTED';
export const LANGUAGE_AVAILABLE = 'LANGUAGE_AVAILABLE';

// Remote systems
export const REMOTE_SYSTEMS_GOT = 'REMOTE_SYSTEMS_GOT';
export const GOT_ENTITY_SETTINGS = 'GOT_ENTITY_SETTINGS';
export const GOT_SYSTEM_SETTINGS = 'GOT_SYSTEM_SETTINGS';
