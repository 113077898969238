import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { api } from '../providers/ApiProvider'
import { getGuiUser, logout } from '../providers/ReduxProvider/actions/userActions';
import { __, showMessage } from "../utils/appHelper";
import { getBaseUrl } from "../constants/globalConfiguration";
import * as S from '../providers/StyleProvider/styleComponents';

const LoggedPage = (props) => {

  const user = useSelector((state) => state?.user?.data);
  const url = useSelector((state) => state?.ui?.baseURI);
  const [ordersCount, setOrdersCount] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    props.getGuiUser();
  }, []);

  useEffect(() => {
    if (user) {
      setIsLoading(true);
      api.get(`orders`)
        .then(response => {
          setIsLoading(false);
          if (response.headers && response.headers["x-total-count"]) {
            setOrdersCount(response.headers["x-total-count"]);
          }
        })
        .catch(error => {
          if (error !== "logout")
            showMessage('error', '<strong>' + 'Error loading orders.' + '</strong><br\>' + error);
        });
    }
  }, [user]);

  const handleLogout = () => {
    props.logout();
  };

  const consolidateURL = (url) => {
    if (url && url.startsWith('api.') && url.endsWith('app'))
      return url.replace('api.', '')
    return url
  }

  return (
    <div>
      <div className='row'>
        {user &&
          <div className="text-center">
            <br />
            <div>{__('Welcome back')}, {user?.firstName}!</div>
            <div style={{ marginTop: 5 }}>
              {
                (isLoading) ? <Spin /> :
                  <span>{__("You have")} {ordersCount ? ordersCount : 0} {__("Pending orders")}.</span>
              }
            </div>
            <br />
            <br />
            <S.Button className="btn btn-success">
              <a target="_blank" href={getBaseUrl(consolidateURL(url))}>{__('Go check it out')}</a>
            </S.Button>
            <div style={{ marginTop: 10 }}>
              <span>{__("or")}</span>&nbsp;<a onClick={handleLogout}>{__("Logout")}</a>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default connect(
  (state) => {
    return {
      user: state.user
    };
  },
  { logout, getGuiUser }
)(LoggedPage);

LoggedPage.propTypes = {};
