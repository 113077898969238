import {isLight} from "../../utils/styleUtils";

export const baseColors = {
    black: {
        primary: "#080808",
        secondary: "#314659"
    },
    gray: {
        primary: "#d8d8d8",
        secondary: "#e8e8e8",
    },
    white: {
        primary: "#ffffff",
    },
    text: {
        primary: "#4d4d4d"
    },
    grey: {
        primary: "#d8d8d8",
        secondary: "#e8e8e8",
    },
    lightGray: {
        primary: "#f2f4f5",
        secondary: "#fafafa",
    },
    blue: {
        primary: "#1890ff",
        secondary: "#40a9ff"
    },
    red: {
        primary: "#ea4335",
        secondary: "#ea4335"
    }
};


export const classicTheme = () => {
    return ({
        colors: {
            ...baseColors,
            primary: "#0a4f7a",
            secondary: "#008db0",
            tertiary: "#a8d15d",
            text: "#ffffff",
        }
    });
};

export const lightTheme = () => {
    return ({
        colors: {
            ...baseColors,
            primary: "#fefefe",
            secondary: "#008db0",
            tertiary: "#a8d15d",
            text: "#222222",
        }
    });
};

export const darkTheme = () => {
    return ({
        colors: {
            ...baseColors,
            primary: "#222222",
            secondary: "#008db0",
            tertiary: "#a8d15d",
            text: "#ffffff",
        }
    });
};

export const customTheme = (props) => {
    return ({
        colors: {
            ...baseColors,
            primary: props.primary,
            secondary: props.secondary,
            tertiary: props.tertiary,
            text: isLight(props.primary) ? "#222222" : "#ffffff",
        }
    });
};