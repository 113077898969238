import * as actions from '../../../constants/actionTypes';
import initialState from './initialState';
import { THEME_CLASSIC, THEME_CUSTOM, THEME_DARK, THEME_LIGHT } from "../../../utils/constants";

export default function uiUpdate(state = initialState.ui, { type, payload }) {
  switch (type) {
    case actions.SET_HOME_URL:
      return {
        ...state,
        homeURL: payload
      }
    case actions.SET_BASE_URI:
      return {
        ...state,
        baseURI: payload
      }
    case actions.SET_ENTITY_NAME_FOR_SIGN_UP:
      return {
        ...state,
        entityNameForSignUp: payload
      }

    case actions.GOT_ENTITY_SETTINGS:
      return {
        ...state,
        entitySettings: payload
      }

    case actions.THEME_SELECTED:
      if (payload.theme !== THEME_CLASSIC && payload.theme !== THEME_LIGHT && payload.theme !== THEME_DARK && payload.theme !== THEME_CUSTOM)
        return { ...state };
      return {
        ...state,
        theme: payload.theme
      }
    case actions.CUSTOM_COLORS_SELECTED:
      return {
        ...state,
        customColors: payload
      }

    case actions.LANGUAGE_SELECTED:
      return {
        ...state,
        language: payload.lang
      };

    case actions.LANGUAGE_AVAILABLE:
      return {
        ...state,
        availableLanguages: payload
      }

    case actions.REMOTE_SYSTEMS_GOT:
      return {
        ...state,
        remoteSystems: payload
      }

    default:
      return state;
  }
}
