import React from "react";
import {Button, Popover} from "antd";
import PropTypes from 'prop-types';
import {ChromePicker} from "react-color";
import {__} from "../../utils/appHelper";

const ColorPicker = (props) => {
    const {value, onChange} = props;
    return (
        <div>
            <Popover
                content={<ChromePicker
                    color={value}
                    onChange={(color) => onChange(color?.hex)}
                />}
                title="Set color"
                trigger="click"
            >
                <Button style={{backgroundColor: value, width: 128}}>
                    {(value) ? value : __('Pick a color')}
                </Button>
            </Popover>
        </div>
    );
};

export default ColorPicker;

ColorPicker.defaultProps = {};

ColorPicker.props = {
    value: PropTypes.any,
    onChange: PropTypes.func,
};