import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { getApiHandler } from '../../utils/appHelper'
import {setBaseURI, setEntityNameForSignUp} from "../ReduxProvider/actions/userActions";
import {parse} from 'query-string'

const ApiContext = React.createContext()

export let api = getApiHandler();

// ✅ in the furure fix here the api providers!
// put here axios instance
const ApiProvider = (props) => {
  const { children } = props;

  const url = useSelector((state) => state?.ui?.baseURI);
  const token = useSelector((state) => state?.user?.auth?.token);

  useEffect(() => {
      const search = parse(props.location.search);
      api = getApiHandler(search?.url);
      setBaseURI(search?.url);
      setEntityNameForSignUp(search?.entityName);
  }, []);

  useEffect(() => {
    api = getApiHandler(url)
  }, [url, token]);

  return <ApiContext.Provider value={[]}>{children}</ApiContext.Provider>
};

export default withRouter(ApiProvider);