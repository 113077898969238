import React,{ createRef, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

export let hashHistory = createRef();

export default ({ children }) => {
  let history = useHistory();
  useEffect(() => {
    if(!history) return;
    hashHistory = history

  }, [history]);

  return <>{children}</>
}