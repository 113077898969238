import * as constants from '../../../constants/actionTypes';
import * as config from '../../../constants/globalConfiguration';
import { hashHistory } from '../../HistoryProvider';
import { __, getApiHandler, getAuthData, showMessage } from '../../../utils/appHelper';
import moment from 'moment';

import { api } from '../../ApiProvider'
import { urlAny, urlGodoo, urlLocalhost } from '../../../constants/regexes';


export const setHomeUrl = (payload) => ({
  type: constants.SET_HOME_URL,
  payload
})

export const loginErrorAction = (payload) => ({
  type: constants.USER_LOGIN_ERROR,
  payload
})

export const loginErrorActionCreator = (payload) => (dispatch) => {
  dispatch(loginErrorAction(payload))
}

export function login(data) {
  return function (dispatch) {
    dispatch({
      type: constants.USER_LOGGING_IN
    });
    return tryLogin(data)
      .then(
        response => {
          localStorage.setItem('auth', JSON.stringify({ token: response.data.token }));
          dispatch({
            type: constants.USER_LOGGED_IN,
            payload: response.data
          });
        },
        error => dispatch(
          loginErrorAction(error.response?.data?.message))
      )
      .then(() => {

        // Set user token an default header.
        const token = getAuthData()?.token

        if (token) {
          api.defaults.headers.common['x-auth-token'] = token
        }

        if (data?.nextUrl === '/') {
          data.nextUrl = config.HOME_URL;

        }
        //  to nextUrl
        if (data?.nextUrl?.match("http[s]?:\\/\\/.*")) {
          if (data?.nextUrl?.match("http[s]?:\\/\\/.*.?godoo.ch\\/?.*") || data?.nextUrl?.match("http[s]?:\\/\\/localhost\\/?.*")) {
            let url = new URL(data.nextUrl);
            url.searchParams.set("token", getAuthData().token);
            window.location = url.toString();
          } else {
            window.location = data.nextUrl;
          }
        }
      });
  };
}

export function forgotPasswordRequest(data) {
  return function (dispatch) {
    dispatch({
      type: constants.USER_FORGOT_PASSWORD_REQUEST_START
    });
    return tryForgotPassword(data)
      .then(
        response => {
          showMessage('success', __('Request sent successfully. Please check your email'));
          dispatch({
            type: constants.USER_FORGOT_PASSWORD_REQUEST_FINISHED,
            payload: response.data
          });

        },
        error => dispatch({
          type: constants.USER_FORGOT_PASSWORD_REQUEST_ERROR,
          payload: error.response.data.message
        }),
      )
      .then(() => {
        hashHistory.push('/login');
      });
  };
}

export const availableBehaviours = {
  REDIRECT_TO_LOGIN_PAGE: 'REDIRECT_TO_LOGIN_PAGE',
  AUTOMATIC_LOGIN: 'AUTOMATIC_LOGIN',
  REDIRECT_TO_SUCCESSFUL_SIGN_UP_PAGE: 'REDIRECT_TO_SUCCESSFUL_SIGN_UP_PAGE'
}

export const handleStoreAuthToken = ({ authData, nextUrl = '/' }, dispatch) => {
  localStorage.setItem('auth', JSON.stringify({ token: authData.token }))
  dispatch({
    type: constants.USER_LOGGED_IN,
    payload: authData
  });
  setDefAuthHeader()
  goToNextURL(nextUrl)
}

const setDefAuthHeader = () => {
  const token = getAuthData()?.token

  if (token) {
    api.defaults.headers.common['x-auth-token'] = token
  }
}

const goToNextURL = (nextUrl) => {
  if (nextUrl.match(urlAny)) {
    if (nextUrl.match(urlGodoo) || nextUrl.match(urlLocalhost)) {
      const url = new URL(nextUrl)
      url.searchParams.set('token', getAuthData().token)
      window.location = url.toString()
    } else {
      window.location = nextUrl
    }
  } else {
    hashHistory.push(nextUrl)
  }
}

export function signUpRequest(entity, data, url, onSuccessfulSignUp, hasLogin) {
  return function (dispatch) {
    dispatch({
      type: constants.USER_SIGN_UP_START
    });
    return trySignUp(entity.id, data)
      .then(
        response => {
          showMessage('success', __('Registered successfully'));

          dispatch({
            type: constants.USER_SIGN_UP_FINISHED,
            payload: response
          });

          switch (onSuccessfulSignUp) {
            case availableBehaviours.REDIRECT_TO_LOGIN_PAGE && hasLogin:
              hashHistory.push('/login?url=' + url);
              break
            case availableBehaviours.AUTOMATIC_LOGIN:
              if (response?.data?.token) {
                handleStoreAuthToken({ authData: response?.data, nextUrl: "/logged" }, dispatch)
                break
              }
            case availableBehaviours.REDIRECT_TO_SUCCESSFUL_SIGN_UP_PAGE:
            default:
              hashHistory.push(`/successfulRegistration/${entity.name}`)
              break
          }
        },
        error => dispatch({
          type: constants.USER_SIGN_UP_ERROR,
          payload: error.response
        }),
      )
  };
}

export function logout() {
  localStorage.removeItem('auth');

  setTimeout(() => {
    hashHistory.push('/login');
  }, 1000);

  return function (dispatch) {
    dispatch({
      type: constants.USER_LOGGED_OUT
    });
  };
}

export function setLanguage(lang) {

  if (!lang) {
    lang = "en_GB";
  }

  localStorage.setItem('lang', lang);
  moment.locale(lang.split('_')[0])

  return function (dispatch) {
    dispatch({
      type: constants.LANGUAGE_SELECTED,
      payload: { lang: lang }
    });
  };
}

function tryLogin(data) {
  return api.post('login', data.values);
}

function tryForgotPassword(data) {
  return api.post('forgotPassword', data.values);
}

function trySignUp(entityId, data) {
  return api.post(`register?entityId=${entityId}`, data);
}

function tryGetData(key) {
  return api.get('/users/' + key);
}

export function setBaseURI(uri) {
  return function (dispatch) {
    dispatch({
      type: constants.SET_BASE_URI,
      payload: uri,
    });
  }
}

export function setEntityNameForSignUp(uri) {
  return function (dispatch) {
    dispatch({
      type: constants.SET_ENTITY_NAME_FOR_SIGN_UP,
      payload: uri,
    });
  }
}

export function setCustomColors(colors) {
  return function (dispatch) {
    dispatch({
      type: constants.CUSTOM_COLORS_SELECTED,
      payload: colors,
    });
  }
}

export function setTheme(theme) {
  // var c = c.substring(1);      // strip #
  // var rgb = parseInt(c, 16);   // convert rrggbb to decimal
  // var r = (rgb >> 16) & 0xff;  // extract red
  // var g = (rgb >>  8) & 0xff;  // extract green
  // var b = (rgb >>  0) & 0xff;  // extract blue
  //
  // var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
  //
  // if (luma < 40) {
  //     // pick a different colour
  // }

  return function (dispatch) {
    dispatch({
      type: constants.THEME_SELECTED,
      payload: { theme: theme },
    });
  }
}

export function getGuiUser() {

  return function (dispatch) {
    return api.get('/me')
      .then(
        response => {
          dispatch({
            type: constants.USER_GOT_DATA,
            payload: response.data
          });
        }
      ).catch(() => {
        //showMessage('error', __('GetGUIUserServerSideError'));
        logout();
      });
  };
}

export function getUserOrderTypes(dispatch) {
  return api.get('/orderTypes')
    .then(
      response => {
        dispatch({
          type: constants.USER_GOT_ORDERTYPES,
          payload: response.data
        });
      }
    );
}

export function getEntitySettings(entityId) {
  return function (dispatch) {
    return api.get(`entitySettings/${entityId}`)
      .then(
        response => {
          dispatch({
            type: constants.GOT_ENTITY_SETTINGS,
            payload: response.data
          });
        });
  }
}

export function getLanguages(dispatch, getState) {

  const base_URI = getState().ui.baseURI
  let api = getApiHandler(base_URI);
  return api.get(`${config.prefix(base_URI)?.TRANSLATION || ''}/languages?enabled=true`, { data: null })
    .then(
      (response) => {
        dispatch({
          type: 'LANGUAGE_AVAILABLE',
          payload: response.data
        });
      }
    ).catch(() => {
      dispatch({
        type: 'LANGUAGE_AVAILABLE',
        payload: null
      })
    });
}

export function getLanguagesWrapped() {
  return getLanguages;
}