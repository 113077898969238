import styled from "styled-components"

import { isLight } from "../../utils/styleUtils";
import { Radio } from "antd";

export const Button = styled.button`
  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.95);
  }

  &:disabled {
    filter: opacity(0.4) saturate(0.8);
  }

  .btn-primary {
    background-color: ${props => props.theme.colors.secondary};
    color: ${props => (isLight(props.theme.colors.secondary)) ? props.theme.colors.text : props.theme.colors.white.primary}
  }

  .btn-success {
    background-color: ${props => props.theme.colors.tertiary};
    color: ${props => (isLight(props.theme.colors.tertiary)) ? props.theme.colors.text : props.theme.colors.white.primary}
  }
`;

export const ThemeWrapper = styled.div`
  height: 100%;
  padding: 15px;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.text};
  overflow: auto;

  button .btn {
    background-color: ${props => props.theme.colors.tertiary};
    color: ${props => (isLight(props.theme.colors.tertiary)) ? props.theme.colors.text : props.theme.colors.white.primary}
  }

  a {
    color: ${props => props.theme.colors.text}
  }
`;

export const Logo = styled.div`
  position: relative;
  width: 100%;
  margin-left: 25px;
  margin-top: 15px;
  margin-bottom: 40px;
  align-self: start;
  justify-self: start;
  place-self: start;
`;

export const LogoText = styled.div`
  font-weight: 400;
  letter-spacing: 0.04rem;
  font-size: 2.5rem;
  position: absolute;
  bottom: 0.1rem;
  left: 4.8rem;
  font-family: sans-serif;
  color: black;
`;

export const RadioButtonGroup = styled(Radio.Group)`
  display: flex;
  .ant-radio-button-wrapper {
    flex-grow: 1;
    text-align: center;
  }
`;