import * as actions from '../../../constants/actionTypes';
import initialState from './initialState';

export default function userUpdate(state = initialState.user, { type, payload }) {

  switch (type) {
    case actions.USER_LOGGING_IN:
      return {
        ...state,
        isLoading: true
      }
      
    case actions.USER_LOGGED_IN:
      return {
        ...state,
        auth: { token: payload.token },
        isLoading: false
      }

    case actions.USER_GOT_DATA:
      return {
        ...state,
        data: payload
      }

    case actions.USER_GOT_REPORTS:
      return {
        ...state,
        reports: payload
      }

    case actions.USER_GOT_ORDERGROUPS:
      return {
        ...state,
        orderGroups: payload
      }

    case actions.USER_GOT_VIEWS:
      return {
        ...state,
        views: payload
      }

    case actions.USER_GOT_ORDERTYPES:
      return {
        ...state,
        orderTypes: payload
      }

    case actions.USER_GOT_RIGHTS:
      return {
        ...state,
        data: {
          ...state.data,
          rights: payload
        }
      }

    case actions.USER_LOGIN_ERROR:
      return {
        ...state,
        data: null,
        isLoading: false,
        auth: null
      }

    case actions.USER_LOGGED_OUT:
      return {
        ...state,
        data: null,
        auth: null
      }

    default:
      return state;
  }
}
