import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BodyClass from "../containers/BodyClass";
import { THEME_CUSTOM } from "../utils/constants";
import * as queryString from "query-string";
import {
  getEntitySettings,
  setCustomColors,
  setTheme,
  setHomeUrl,
} from "../providers/ReduxProvider/actions/userActions";
import * as S from "../providers/StyleProvider/styleComponents";


const FullPage = (props) => {
  const { children } = props;
  const search = queryString.parse(props.location.search);

  if (search != null && search.theme != null) {
    props.setTheme(search.theme);
  }

  if (search?.allow != null) {
    props.setHomeUrl(search.allow === 'signup' ? `/sign-up/${search.entityName}${props.location.search}` : `/login${props.location.search}`);
  }

  if (search != null && search.theme === THEME_CUSTOM) {
    if (search?.primary && search?.secondary && search?.tertiary) {
      props.setCustomColors({
        primary: `#${search?.primary}`,
        secondary: `#${search?.secondary}`,
        tertiary: `#${search?.tertiary}`,
      })
    }
  }

  return (
    <BodyClass {...props} layout={props.theme}>
      <S.ThemeWrapper>
        {children}
      </S.ThemeWrapper>
    </BodyClass>
  );
};

FullPage.propTypes = {
  children: PropTypes.element.isRequired,
  theme: PropTypes.string,
  setTheme: PropTypes.func,
  getEntitySettings: PropTypes.func,
};

export default connect(
  state => {
    return {
      theme: state.ui.theme
    };
  },
  { setTheme, setCustomColors, getEntitySettings, setHomeUrl },
)(FullPage);
