import { api } from "./index";
import { ERROR } from "components/dist/Utils/LoggerUtils";

export const fetchAvailableEntities = () =>
  api.get('register/availableEntities')
    .then(
      (response) => response.data,
      (error) => {
        ERROR(error)
      }
    )