import React from 'react'
import {createGlobalStyle, ThemeProvider} from 'styled-components'
import './styles/index.scss'
import {useSelector} from "react-redux";
import {classicTheme, customTheme, darkTheme, lightTheme} from "./theme";
import {THEME_CUSTOM, THEME_DARK, THEME_LIGHT} from "../../utils/constants";
import * as S from "./styleComponents";

const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
      box-sizing: border-box;
  }
`;

export default ({children}) => {
    const ui = useSelector(state => state.ui);
    let theme = classicTheme;

    switch (ui?.theme) {
        case THEME_CUSTOM:
            theme = customTheme(ui?.customColors);
            break;
        case THEME_LIGHT:
            theme = lightTheme();
            break;
        case THEME_DARK:
            theme = darkTheme();
            break;
    }

    return (
        <>
            <GlobalStyle/>
            <ThemeProvider theme={theme}>
                    {children}
            </ThemeProvider>
        </>
    )
}