export const T = {
  // SIGN IN
  login: 'login',
  username: 'username or email address',
  password: 'password',
  badCredentials: 'err-wrong-credentials',
  userNotYetActive: 'user_not_yet_active',
  userNoLongerActive: 'user_no_longer_active',

  // SIGN UP - ENTITY SELECTION
  selectEntity: 'company or user group name',

  // SIGN UP
  signUp: 'sign up',
  registrationNotEnabled: 'self registration is not enabled, please contact the management',

  // SIGN UP - VALIDATION
  requiredField: 'the field is mandatory',
  minLength: 'minimum length not reached',
  maxLength: 'maximum length exceeded',
  illegalChars: 'the following characters are illegal',
  invalidInput: 'invalid input',
  invalidPhoneNumber: 'invalid phone number',
  invalidPrefix: 'add country prefix',
  invalidEmail: 'email not valid',
  invalidDomain: 'email domain not allowed',

  // successfulRegistration
  goBackToLogin: 'go back to login',
  passwordsMustMatch: 'PasswordDoesntMatch',

  // FORGOT PASSWORD
  forgotPassword: 'forgot password',
  requestPasswordReset: 'request password reset',

  // RESET PASSWORD
  newPassword: 'New Password',
  confirmPassword: 'Confirm Password',

  // SETTINGS
  logoAddedCorrectly: 'logo added correctly',
  logoRemovedCorrectly: 'logo removed correctly',
  reports: 'reports',
  pinnedViews: 'pinned views',
  pinnedReports: 'pinned reports',
  openAttributesSelection: 'open attributes selection',
  dashboardName: 'dashboard name',
  loginPage: 'login_page',
  autoLogin: 'auto_login',
  autoLoginDisabledInfo: 'auto_login_disabled_info',
  messagePage: 'message_page',

  // SETTINGS - PANELS
  userSelfRegistration: 'user self registration',
  userSelfRegistrationSub: 'Choose settings for the self sign-up of your users, including user-groups, roles and allowed email domains',
  widgetSettings: 'widget settings',
  widgetSettingsSub: 'select widget and dashboard settings',
  dashboardsConfiguration: 'dashboards configuration',
  dashboardsConfigurationSub: 'here you can pin your favourite dashboard',
  instanceConfigurations: 'instance system configurations',
  instanceConfigurationsSub: 'change instance system configurations such as the logo, page sizes, pinned views and pinned reports',

  // SETTINGS - SELF REGISTRATION
  selfRegistration: 'self registration',
  targetUserGroups: 'target user-groups',
  targetUserRoles: 'target user-roles',
  selectTargetGroups: 'select target entities / user groups',
  selectTargetGroupRoles: 'select target entities / user groups roles',
  allowedDomains: 'allowed domains',
  noEntryAddedYet: 'no entry added yet',
  emailVerification: 'email verification',
  registrationBehaviour: 'registration_behaviour',
  successMessage: 'success_message',
  checkYourEmailMessage: 'successful_sign_up_page_message_check_your_email',
  greetingsMessage: 'successful_sign_up_page_message_greetings',
  registrationFormFields: 'registration form fields',

  // MEASURES
  lastUpdateDateTime: 'last update dateTime',
  max: 'max',
  maxDef: 'max default',
  min: 'min',

  // ConfigurationConfiguratorBeta - HEADER/FOOTER
  chooseFieldsValidationError: 'choose_fields_validation_error',
  viewPropertiesValidationError: 'view_properties_validation_error',

  // GENERAL VALIDATIONS
  userEmailAlreadyExists: 'user_email_already_exists',

  // MISCELLANEOUS
  back: 'back',
  next: 'next',
  or: 'or',
  default: 'default',
  confirm: 'Confirm',
  webApp: 'web app',
  mobileApp: 'mobile app',
  getTheApp: 'get the app',
  edit: 'edit',
  save: 'save',
  remove: 'remove',
  upload: 'upload',
  uploadLogo: 'upload logo',
  pageSize: 'page size',
  views: 'views',
  unauthorized: 'unauthorized',
  unauthorizedExtended: 'sorry, you are not authorized to modify',
  unknownError: 'unknown_error',
  error: 'error'
}
