import * as actions from '../../../constants/actionTypes';
import initialState from './initialState';
import objectAssign from 'object-assign';

export default function userUpdate(state = initialState.errors, { type, payload }) {

  switch (type) {

    case actions.USER_LOGGING_IN:
      return objectAssign({}, state, {'loginError': null});
    
    case actions.USER_LOGIN_ERROR:
      return objectAssign({}, state, {'loginError': payload});

    default:
      return state;
  }
  
}
