import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom';
import FullPage from './layouts/FullPage';
import LoginPage from "./containers/LoginPage";
import ForgotPasswordPage from "./containers/ForgotPasswordPage";
import SignUpPage from "./containers/SignUpPage";
import WidgetConfigPage from "./containers/WidgetConfigPage";
import LoggedPage from "./containers/LoggedPage";
import SuccessMessage from './containers/SuccessMessage';


const CustomRoute = (props) => {
  const { Component } = props;
  return (
    <Route
      {...props}
      render={(props) => (
        <FullPage {...props}>
          <Component {...props} />
        </FullPage>
      )}
    />
  )
}

function pageInIframe() {
  return (window.location !== window.parent.location);
}

const Routes = () => {
  const isIframe = pageInIframe()

  if (isIframe)
    return (
      <Switch>
        <CustomRoute exact path="/" Component={LoginPage} />
        <CustomRoute exact path="/login" Component={LoginPage} />
        <CustomRoute exact path="/logged" Component={LoggedPage} />
        <CustomRoute exact path="/forgot-password" Component={ForgotPasswordPage} />
        <CustomRoute exact path="/sign-up/:entityName" Component={SignUpPage} />
        <CustomRoute path="/successfulRegistration/:entityName" Component={SuccessMessage} />
        <Route path="*"><Redirect to="/login" /></Route>
      </Switch>
    )

  return (
    <Switch>
      <Route exact path="/config" component={WidgetConfigPage} />
      <Route path="*"><Redirect to="/config" /></Route>
    </Switch>
  )
}

export default Routes;
