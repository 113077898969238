import React from 'react'
import { createStore, compose, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk';

import rootReducer from './reducers/'
import initialState from './reducers/initialState'



let store = null

if(process.env.NODE_ENV === 'production') {
  store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(thunk)
    )
  )
} else {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(
            applyMiddleware(thunk),
        )
    );
}


export default ({ children }) => (
  <Provider store={store}>
    {children}
  </Provider>
)








