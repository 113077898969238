import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Layout,
  Row,
  Select,
  Typography,
  Modal,
  Switch,
  Radio,
} from 'antd';
import { __, renameProp } from "../utils/appHelper";
import axios from 'axios'

import { THEME_CLASSIC, THEME_CUSTOM, THEME_DARK, THEME_LIGHT } from "../utils/constants";
import Iframe from "react-iframe";
import MessageView from "../components/common/MessageView";
import ColorPicker from "../components/common/ColorPicker";
import { getApiUrl } from "../constants/globalConfiguration";
import { connect, useSelector } from "react-redux";
import { getLanguagesWrapped, setBaseURI } from "../providers/ReduxProvider/actions/userActions";
import * as S from "../providers/StyleProvider/styleComponents";
import { api } from '../providers/ApiProvider';
import HorizontalSwitch from "../components/common/Switch";


const { Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;

const WidgetConfigPage = (props) => {

  const { updateLanguages, updateBaseURI } = props

  const availableLanguages = useSelector((state) => state?.ui.availableLanguages);

  const [preview, setPreview] = useState(null);
  const [widget, setWidget] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [entitySettings, setEntitySettings] = useState(null)
  const [availableEntities, setAvailableEntities] = useState(null);
  const [update, setUpdate] = useState(-1);
  const [showCustomSettings, setShowCustomSettings] = useState(false);
  const [showLanguages, setShowLanguages] = useState(false)

  const [form] = Form.useForm();

  const MIN_WIDTH = 400;
  const MAX_WIDTH = 1920;
  const MIN_HEIGHT = 600;
  const MAX_HEIGHT = 1080;

  const GODOO_DEFAULT_URL = 'test.godoo.ch';


  useEffect(() => {
    updateBaseURI(window.location.hostname);
    getAvailableCompanies();
    updateLanguages()
  }, []);


  function getAvailableCompanies(url) {
    if (!url) {
      url = getApiUrl(window.location.hostname);
    }

    return axios.get(`${url}/register/availableEntities`)
      .then(
        response => {
          let data = response.data;
          data.forEach(obj => renameProp(obj, 'name', 'value'));
          setAvailableEntities(data);
        },
        error => {
          console.log(error);
          setAvailableEntities(null);
        }
      );
  }

  const onFinish = (values) => {
	  setPreview(null)
    const resource = (!values.allow || values.allow !== "signup") ? "login" : `sign-up/${values.entityName}`;

    let src = `${window.location.origin}${window.location.pathname}#/${resource}?url=${values.url}&theme=${values.theme}`;
    if (values.entityName) {
      src += `&entityName=${values.entityName}`;
    }
    if (values.allow) {
      src += `&allow=${values.allow}`;
    }
    if (values.theme === THEME_CUSTOM) {
      src += `&primary=${values?.primary.substring(1)}
                    &secondary=${values?.secondary.substring(1)}
                    &tertiary=${values?.tertiary.substring(1)}`
    }

    if (!values.languageAuto) {
      src += `&language=${values?.language}`
    }
    /*
      Widget preview
     */
    const widgetOptions = {
      src,
      width: values.width,
      height: values.height,
      allow: values.allow
    };
    setPreview(widgetOptions);
    /*
      Widget export
     */
    const iFrame = `<iframe src="${src}"
                        allow="${values.allow}"
                        style="border:none;overflow:hidden"
                        width="${values.width}" height="${values.height}"
                        >
                    </iframe>`;
    setWidget(iFrame);
    setUpdate(0);
  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues.entityName) {
      api.get(`register/availableEntities/${changedValues.entityName}`).then((response) => {
        api.get(`entitySettings/${response.data.id}`).then((res) => {
          setEntitySettings(res.data)
        });
      });
    }
		if (changedValues.languageAuto === false) {
			form.setFieldsValue({ ['language']: null })
		}
  };

  const themeSelected = (theme) => {
    (theme === THEME_CUSTOM) ? setShowCustomSettings(true) : setShowCustomSettings(false);
  };

  const onUrlBlur = (e) => {
    form.setFieldsValue({ entityName: "" });
    updateBaseURI(e.target.value);
    getAvailableCompanies(getApiUrl(e.target.value));
    updateLanguages()
  };

  return (
    <Layout style={{ height: "100vh", backgroundColor: "white", }}>
      <Content>
        <Row style={{ height: "100%" }}>
          <Col xs={24} sm={24} md={24} lg={10} xl={8} xxl={6} style={{
            backgroundColor: "#f0f2f5",
            textAlign: "left",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
          }}>
            <S.Logo>
              {<img height={58} alt="" src={process.env.PUBLIC_URL + "/static/logo-full.svg"} />}
              <S.LogoText>Plugin</S.LogoText>
            </S.Logo>
            <div style={{
              width: "350px",
              margin: "0 auto",
            }}>
              <Title level={3}>{__("configuration")}</Title>
              <Form
                name="widgetConfig"
                form={form}
                onFinish={onFinish}
                onValuesChange={onValuesChange}
                layout="vertical"
                initialValues={{
                  ['theme']: THEME_CLASSIC,
                  ['width']: MIN_WIDTH,
                  ['height']: MIN_HEIGHT,
                  ['languageAuto']: true,
                  ['allow']: "both"
                }}
              >
                <Form.Item
                  name="url"
                  label="Website"
                  rules={[
                    {
                      required: true,
                      message: __("please insert the godoo installation url"),
                    },
                  ]}
                >
                  <Input
                    onBlur={onUrlBlur}
                    placeholder={GODOO_DEFAULT_URL}
                  />
                </Form.Item>
                <Form.Item
                  name="entityName"
                  label={__("company or user group")}
                  rules={[
                    {
                      required: (availableEntities?.length > 0),
                      message: __("please select the company or user-group name for sign-up"),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    options={availableEntities}
                    placeholder={__('company or user group name')}
                    filterOption={(input, option) =>
                      option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  />
                </Form.Item>
                <Divider />
                <Input.Group compact
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Form.Item name="width" label={__("width")}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: __('please select the dimension'),
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder={MIN_WIDTH}
                      min={MIN_WIDTH}
                      max={MAX_WIDTH}
                      step={50}
                      style={{ width: '165px' }}
                    />
                  </Form.Item>
                  <div style={{ width: "30px" }} />
                  <Form.Item name="height" label={__("height")}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: __('please select the dimension'),
                      },
                    ]}
                    style={{ flex: 'initial' }}
                  >
                    <InputNumber
                      placeholder={MIN_HEIGHT}
                      min={MIN_HEIGHT}

                      max={MAX_HEIGHT}
                      step={50}
                      style={{ width: '165px' }}
                    />
                  </Form.Item>
                </Input.Group>

                <Form.Item
                  name="theme"
                  label={__("select theme")}
                  tooltip={__("if you want to customize all the colors, please select the custom theme")}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: __('please select the theme'),
                    },
                  ]}
                >
                  <Select placeholder={__("select theme")}
                    onSelect={themeSelected}
                  >
                    <Option value={THEME_CLASSIC}>{__(THEME_CLASSIC)}</Option>
                    <Option value={THEME_DARK}>{__(THEME_DARK)}</Option>
                    <Option value={THEME_LIGHT}>{__(THEME_LIGHT)}</Option>
                    <Option value={THEME_CUSTOM}>{__(THEME_CUSTOM)}</Option>
                  </Select>
                </Form.Item>

                {
                  (showCustomSettings) && (
                    <div>
                      <Form.Item
                        name="primary"
                        label={__("primary color")}
                        hasFeedback
                        rules={[
                          {
                            required: showCustomSettings,
                            message: __('Please select the color'),
                          },
                        ]}
                      >
                        <ColorPicker {...props} />
                      </Form.Item>
                      <Form.Item
                        name="secondary"
                        label={__("secondary color")}
                        hasFeedback
                        rules={[
                          {
                            required: showCustomSettings,
                            message: __('Please select the color'),
                          },
                        ]}
                      >
                        <ColorPicker {...props} />
                      </Form.Item>
                      <Form.Item
                        name="tertiary"
                        label={__("tertiary color")}
                        hasFeedback
                        rules={[
                          {
                            required: showCustomSettings,
                            message: __('Please select the color'),
                          },
                        ]}
                      >
                        <ColorPicker {...props} />
                      </Form.Item>
                    </div>
                  )
                }
                {
                  (entitySettings?.userRegistration?.selfRegistrationEnabled) && (
                    <>
                      <Divider />
                      <Form.Item
                        name="allow"
                        label={__("allow")}
                        hasFeedback
                        rules={[
                          { required: true },
                        ]}
                      >
                        <S.RadioButtonGroup defaultValue="both" style={{ display: "flex" }}>
                          <Radio.Button value="login" >{__("login")}</Radio.Button>
                          <Radio.Button value="signup">{__("signup")}</Radio.Button>
                          <Radio.Button value="both">{__("both")}</Radio.Button>
                        </S.RadioButtonGroup>
                      </Form.Item>
                    </>
                  )
                }

                <Divider />

                <Form.Item
                  name="languageAuto"
                  hasFeedback
                  style={{ width: "100%" }}
                >
	                <HorizontalSwitch />
                </Form.Item>
	              <Form.Item
		              noStyle
		              hasFeedback
		              shouldUpdate={(prevValues, currentValues) => prevValues.languageAuto !== currentValues.languageAuto}
	              >
		              {({ getFieldValue }) => {
										return (getFieldValue('languageAuto') !== false ?
											null : (
												<Form.Item
													name="language"
													label={__("select language")}
													hasFeedback
													rules={[{
														validator: (_, value) => {
															if (!value && showLanguages) {
																return Promise.reject()
															}
															return Promise.resolve()
														},
														message: __('please select the language'),
													}]}
												>
													<Select placeholder={__("select language")}>
														{
															(availableLanguages || []).map((lang) =>
																<option key={lang.languageCode}
																        value={lang.languageCode}>{__(`${lang.language}`)}</option>
															)
														}
													</Select>
												</Form.Item>
											)
										)
		              }}
	              </Form.Item>
                <Button htmlType="submit"
                  type={(update === -1) ? "primary" : "success"}
                  style={{ float: "right" }}
                  className="btn-primary"
                >
                  {(update === -1) ? __("Generate") : __("Update")}
                </Button>
              </Form>
            </div>
          </Col>
          <Col
            xs={24} sm={24} md={24} lg={14} xl={16} xxl={18}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "left",
              paddingLeft: "48px",
            }}>
            <div style={{ display: "table-cell", verticalAlign: "middle", padding: "10px 0" }}>
              <Title level={3} style={{}}>{__("Preview")}</Title>
              {
                (preview) ? (
                  <div>
                    {
                      (widget && (
                        <div>

                          <Modal title="Copy and paste the godoo plugin code in your website"
                            visible={showModal}
                            closable={false}
                            onOk={() => setShowModal(false)}
                            cancelButtonProps={{ style: { display: 'none' } }}
                          >
                            <Row style={{ textAlign: 'center' }}>
                              <Col span={24}>
                                <code>
                                  <Text copyable>{widget}</Text>
                                </code>
                              </Col>
                            </Row>
                          </Modal>
                        </div>
                      ))
                    }
                    <Iframe url={preview?.src}
                      allow={preview?.allow}
                      width={preview?.width}
                      height={preview?.height}
                      key={update}
                      style="border:none;overflow:hidden"
                      display="initial"
                      position="relative" />
                    {
                      (widget && (
                        <div style={{ marginTop: "0.6em" }}>
                          <Text>{__("copy and paste the godoo plugin code in your website")}</Text>
                          <br /><br />
                          <Button type="primary"
                            onClick={() => setShowModal(true)}
                            className="btn-primary"
                          >
                            {__("get the code")}
                          </Button>
                        </div>
                      )
                      )
                    }
                  </div>
                ) : (
                  <MessageView
                    type='widget2'
                    subtitle={__('here will be displayed your godoo plugin preview')}
                  />
                )}
            </div>
          </Col>
        </Row>
      </Content>
    </Layout >
  );
}
  ;

export default connect(
  () => ({}),
  { updateLanguages: getLanguagesWrapped, updateBaseURI: setBaseURI }
)(WidgetConfigPage)


