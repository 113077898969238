import * as yup from 'yup'
import _ from 'lodash'
import {
  isAValidName,
  isAValidUsername,
  missingNameChars,
  missingUsernameChars,
  REGEX_EMAIL,
  REGEX_PHONE,
  REGEX_PHONE_PREFIX
} from '../constants/regexes'
import { __, cases } from './appHelper'
import { T } from './translations'


export const isRequiredSchema = () => yup.string().required(__(T.requiredField, cases.CAPITALIZE_SENTENCE))
export const minSchema = (number = 1) => yup.string().min(number, __(T.minLength, cases.CAPITALIZE_SENTENCE))
export const maxSchema = (number = 64) => yup.string().max(number, __(T.maxLength, cases.CAPITALIZE_SENTENCE))
export const illegalCharsName = () => yup.string().test('name-illegal-chars', (value, options) => {
  const { createError, path } = options
  const cleanedValue = value && value.replace(/\s/g, '')

  if (cleanedValue && !isAValidName(cleanedValue)) {
    let message = __(T.invalidInput, cases.CAPITALIZE_SENTENCE)
    const illegalChars = missingNameChars(cleanedValue)
    if (!_.isEmpty(illegalChars)) message = `${__(T.illegalChars, cases.CAPITALIZE_SENTENCE)}: ${Array.from(illegalChars).join(', ')}`

    return createError({ message, path })
  }
  return true
})

export const illegalCharsUsername = () => yup.string().test('username-illegal-chars', (value, options) => {
  const { createError, path } = options
  const cleanedValue = value && value.replace(/\s/g, '')

  if (cleanedValue && !isAValidUsername(cleanedValue)) {
    let message = __(T.invalidInput, cases.CAPITALIZE_SENTENCE)
    const illegalChars = missingUsernameChars(cleanedValue)
    if (!_.isEmpty(illegalChars)) message = `${__(T.illegalChars, cases.CAPITALIZE_SENTENCE)}: ${Array.from(illegalChars).join(', ')}`

    return createError({ message, path })
  }
  return true
})
export const phoneSchema = () => yup.string().matches(REGEX_PHONE, __(T.invalidPhoneNumber, cases.CAPITALIZE_SENTENCE))
export const prefixSchema = () => yup.string().matches(REGEX_PHONE_PREFIX, __(T.invalidPrefix, cases.CAPITALIZE_SENTENCE))
export const emailSchema = () => yup.string().matches(REGEX_EMAIL, __(T.invalidEmail, cases.CAPITALIZE_SENTENCE))

export const domainSchema = (domains) => {
  if (!domains || _.isEmpty(domains)) return undefined

  return yup.string().test('allowed-domain', (value, options) => {
    const { createError, path } = options

    if (value && domains.some((d) => value.endsWith(d))) return true
    return createError({ message: __(T.invalidDomain, cases.CAPITALIZE_SENTENCE), path })
  })
}

export default {
  isRequiredSchema,
  minSchema,
  maxSchema,
  illegalCharsName,
  illegalCharsUsername,
  phoneSchema,
  prefixSchema,
  emailSchema,
  domainSchema,
}
