import React from 'react';
import { connect } from 'react-redux';
import * as queryString from 'query-string'
import { parse, stringify } from 'query-string'
import { hashHistory } from '../providers/HistoryProvider';
import PropTypes from 'prop-types';
import { api } from "../providers/ApiProvider";
import i18next from 'i18next';
import * as config from '../constants/globalConfiguration';

import {
  getEntitySettings,
  getLanguagesWrapped,
  getUserOrderTypes,
  setBaseURI,
  setEntityNameForSignUp,
  setLanguage
} from '../providers/ReduxProvider/actions/userActions';
import Loading from "./Loading";
import { FullHeight } from "../components/styles";

class BodyClass extends React.Component {
  static propTypes = {
    layout: PropTypes.string,
    setLanguage: PropTypes.func,
    children: PropTypes.element.isRequired,
    language: PropTypes.string,
    location: PropTypes.object,
    baseURI: PropTypes.string
  }
  static defaultProps = {
    layout: ""
  }

  constructor(props) {
    super(props);
    this.queryString = parse(this.props.location.search)
    this.state = {
      isLoading: true,
    }
  }


  componentWillMount() {
    const { setBaseURI, setEntityNameForSignUp, location } = this.props;
    const search = queryString.parse(location.search);

    setBaseURI(search?.url);
    setEntityNameForSignUp(search?.entityName);

    if (localStorage.getItem('skipTranslations') === 'true') {
      i18next.init({
        lng: 'en',
        resources: {
          en: {
            translation: {},
            fallbackLng: false
          }
        }
      });
      this.setState({ isLoading: false })
      return
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { updateLanguages, setLanguage, language, availableLanguages, location } = this.props;

    if (!availableLanguages || availableLanguages?.length === 0) {
      updateLanguages();
    }

    if (availableLanguages !== prevProps.availableLanguages) {
      // Check if language is in localstorage, if not get it form browser (navigator), if not set it to english
      // get languages codes and adding manually no translation language
      const languageCodes = availableLanguages?.map(l => l.languageCode) || []

      const storageLanguage = localStorage.getItem('lang')

      const navigatorLanguage = navigator.language && navigator.language.replace('-', '_');

      if (this.queryString.language && this.queryString.language !== '') {
        if (typeof hashHistory.push === 'function') {
          hashHistory.push({
            ...location,
            search: stringify({
              ...this.queryString,
              language: ''
            })
          })
        }
        setLanguage(this.queryString.language)
      } else if (languageCodes.includes(storageLanguage)) {
        setLanguage(storageLanguage);
      } else if (languageCodes.includes(navigatorLanguage)) {
        setLanguage(navigatorLanguage);
      } else if (languageCodes.find(lc => (lc.substring(0, 2) === navigatorLanguage.substring(0, 2)))) {
        setLanguage(languageCodes.find(lc => (lc.substring(0, 2) === navigatorLanguage.substring(0, 2))));
      } else {
        setLanguage('en_GB')
      }
    }

    if (language !== prevProps.language) {
      api.get(`${config.prefix(this.props.baseURI)?.TRANSLATION || ''}/translations/${language}/last/export?dirty=true`, { data: null }).then(response => {
        const res = {}
        const responseJson = response.data
        const keyValues = Object.keys(responseJson).map((key) => {
          const newKey = key.trim().toLowerCase()
          return { [newKey]: responseJson[key] }
        })
        Object.assign(res, ...keyValues)

        i18next.init({
          lng: 'en',
          resources: {
            en: {
              translation: res,
              fallbackLng: false
            }
          }
        });

        this.setState({ isLoading: false })
      }).catch(() => {
        this.setState({ isLoading: false })
      });

      try {
        // eslint-disable-next-line global-require,import/no-dynamic-require
        require(`moment/locale/${language.replaceAll('_', '-').toLowerCase()}`)
      } catch (e) {
        console.log(e)
      }
    }
  }

  render() {
    return (

      <FullHeight>
        {this.state.isLoading ? <Loading loading={this.state.isLoading} /> : null}
        {this.state.isLoading ? null : (
          <>{this.props.children}</>
        )}
      </FullHeight>
    )
  }
}

export default connect(
  (state) => {
    return {
      language: state.ui.language,
      theme: state.ui.theme,
      availableLanguages: state.ui.availableLanguages,
      baseURI: state.ui.baseURI,
    };
  },
  {
    updateLanguages: getLanguagesWrapped,
    setLanguage,
    getEntitySettings,
    getUserOrderTypes,
    setBaseURI,
    setEntityNameForSignUp,
  }
)(BodyClass);
